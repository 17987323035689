<template>
  <div>
    <v-menu
      v-model="menu"
      offset-y
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          readonly
          v-bind="[attrs,$props]"
          v-on="{...on,...listeners$}"
        ></v-text-field>
      </template>
      <v-date-picker
        v-if="pickerType === 'date'"
        :value="value"
        header-color="primary"
        color="primary"
        elevation="3"
        show-current
       :max="max"
        :min="min"
        :type="pickerType"
        @click:date="changeDate"
      ></v-date-picker>
      <v-date-picker
        v-if="pickerType === 'month'"
        :value="value"
        header-color="primary"
        color="primary"
        elevation="3"
        show-current

        :type="pickerType"
        @click:month="changeDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { VTextField } from "vuetify/lib";
export default {
  extends: VTextField,
  props: {
    pickerType: {
      type: String,
      default: "date",
    },
    max:{
      type: String,
    },
    min: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    changeDate(e) {
      this.$emit("input", e);
      this.menu = false;
    },
  },
};
</script>
